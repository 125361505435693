// import { MutableRefObject } from "react";

// import { MutableRefObject, useEffect, useState } from "react";
// import { isIOS, isStandalone, showInstallPrompt } from "~/utils/pwa.client";
import { usePWAManager } from "@remix-pwa/client";

import AthletifyIcon from "~/assets/athletify-icon.svg";
import { setHideInstallPrompt } from "~/utils/pwa.client";

export function PWAInstallPrompt({ setHide }: { setHide: () => void }) {
  // const [debugObj, setDo] = useState({} as any);
  // useEffect(() => {
  //   showInstallPrompt().then((show) => {
  //     setDo({
  //       show,
  //       isIOS: isIOS(),
  //       UA: navigator.userAgent,
  //       standalone: isStandalone(),
  //     });
  //   });
  // }, []);
  const mgr = usePWAManager();
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md border-t-slate-400 border-t-2 z-[5001]">
      <div className="items-center justify-between">
        <div>
          <h3 className="text-lg font-semibold text-nowrap">
            <img
              src={AthletifyIcon}
              alt="logo"
              className="h-5 mr-4 inline-block"
            />
            Install Athletify
          </h3>
          <p className="text-sm">
            Add Athletify to your home screen for a better experience.
            {/* {JSON.stringify(debugObj, null, 2)} */}
          </p>
        </div>
        <div className="flex items-center justify-end space-x-2 space-y-2">
          <button
            onClick={() => {
              setHide();
              setHideInstallPrompt();
            }}
            className="text-blue-500"
          >
            Dismiss
          </button>
          <button
            onClick={() => {
              setHide();
              mgr.promptInstall();
            }}
            className="bg-blue-500 text-white rounded-md px-4 py-2"
          >
            Install
          </button>
        </div>
      </div>
    </div>
  );
}
