import { useEffect } from "react";

import toast from "react-hot-toast";

import type { ToastMessage } from "~/interfaces/toast";

export default function useToasts(newMessage: ToastMessage) {
  useEffect(() => {
    if (!newMessage) {
      return;
    }
    switch (newMessage.type) {
      case "success":
        toast.success(newMessage.message, { position: newMessage.position });
        break;
      case "error":
        toast.error(newMessage.message, { position: newMessage.position });
        break;
      default:
        break;
    }
  }, [newMessage]);
}
