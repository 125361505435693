export const UNAUTHENTICATED_ROUTES = [
  "/login",
  "/create-account",
  "/forgot-password",
  "/",
];

export const UNAUTHENTICATED_PATTERNS = [
  /^\/invite\/[^/]+\/accept$/,
  /^\/reset-password\/[^/]+$/,
];
