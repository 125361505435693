import logger from "~/config/logging.client";

export function isIOS() {
  return /iPhone|iPad|iPod/.test(navigator.userAgent || "");
}

export function isAndroid() {
  return /Android/.test(navigator.userAgent || "");
}

export function isMobile() {
  return isIOS() || isAndroid();
}

export function isStandalone() {
  if (!window) {
    return false;
  }
  return window.matchMedia("(display-mode: standalone)").matches;
}

export function getRelatedApps(): Promise<object[]> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyNav = navigator as any;
  if (!anyNav.getInstalledRelatedApps) {
    return Promise.resolve([]);
  }
  try {
    return anyNav.getInstalledRelatedApps();
  } catch (e) {
    logger.error("Failed to get related apps", e);
    return Promise.resolve([]);
  }
}

export async function showInstallPrompt(): Promise<boolean> {
  if (isStandalone() || !isMobile() || isIOS()) {
    // temporarily disable for iOS until we figure out how we want to do this.
    return false;
  }

  if (window?.localStorage.getItem("ATHLETIFY_HIDE_INSTALL_PROMPT")) {
    return false;
  }

  const relatedApps = await getRelatedApps();
  if (relatedApps.length > 0) {
    return false;
  }

  return true;
}

export function setHideInstallPrompt(hide = true) {
  if (!window) {
    return;
  }
  if (hide) {
    window.localStorage.setItem("ATHLETIFY_HIDE_INSTALL_PROMPT", "true");
  } else {
    window.localStorage.removeItem("ATHLETIFY_HIDE_INSTALL_PROMPT");
  }
}
